import React from 'react';

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import Typography from '@material-ui/core/Typography'

import facebook from '../img/social/fb.png'
import instagram from '../img/social/ig.png'

export default function FollowUs({title}){
    return(
        <Box className="keep-up">
            <Card className="keep-up-card">
                <CardHeader
                    className='title'
                    title={title}
                />
                <CardContent>
                    <Typography paragraph>
                        Vi publicerar många av våra kurser och event direkt på sociala medier. 
                        Följ oss så ser du till att inte missa några biljettsläpp!
                    </Typography>
                    <div className="keep-up-icons">
                        <a title="facebook" href="https://www.facebook.com/kvarntakt20.se/">
                            <img
                                src={facebook}
                                alt="Facebook"
                                style={{ width: '3em', height: '3em' }}
                            />
                        </a>
                        <a title="instagram" href="https://www.instagram.com/kvarntakt20/">
                            <img
                                src={instagram}
                                alt="Instagram"
                                style={{ width: '3em', height: '3em' }}
                            />
                        </a>
                    </div>
                </CardContent>
            </Card>
        </Box>
    )
}
