import React from 'react'
import PropTypes from 'prop-types'
import {graphql, StaticQuery } from 'gatsby'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from  '@material-ui/core/Icon'

class ContactInformation extends React.Component {
  render() {
    const { data } = this.props
    const { edges: node } = data.allMarkdownRemark
    const contactInfo = node[0].node.frontmatter.contact_info

    const iconStyle = {
      fontSize: '35px'
    }

    return (
        <div className="contact-info-box">
            <List style={{width: '100%'}}>
          {contactInfo.telefon ? (
            <ListItem key={0}>
              <ListItemIcon>
                <Icon style={iconStyle}>phone</Icon>
              </ListItemIcon>
              <div>
              {contactInfo.telefon.kontakt_info.map((k, i) => {
                return(
                  <div>
                    <span key={0} style={{display: 'block'}}>{k.namn}</span>
                    <span key={1}  className="sub-text" style={{display: 'block'}}>{k.nummer}</span>
                  </div>
                )
              })}
              </div>
            </ListItem>
          ): false}

          {contactInfo.mail ? (
            <ListItem key={1}>
              <ListItemIcon>
                <Icon style={iconStyle}>mail</Icon>
              </ListItemIcon>
              <ListItemText primary={contactInfo.mail}/>
            </ListItem>
          ): false}

          {contactInfo.oppet_tider ? (
            <ListItem key={2}>
              <ListItemIcon>
                <Icon style={iconStyle}>access_time</Icon>
              </ListItemIcon>
              <ListItemText primary={contactInfo.oppet_tider}/>
            </ListItem>
          ): false}
        </List>
      </div>
    )
  }
}

ContactInformation.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ContactinfoQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "contact-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                contact_info{
                    telefon{
                      kontakt_info{
                        namn
                        nummer
                      }
                      bild{
                        relativePath
                      }
                    }
                    mail
                    oppet_tider
                  } 
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ContactInformation data={data} count={count} />}
  />
)
