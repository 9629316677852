import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import KeepUp from '../components/KeepUp'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import ContactInformation from '../components/Contact/ContactInformation'

//Media imports
import AboutImage from '../img/omoss.jpg'
import SwishLogo from '../img/swish.svg'
import Content, { HTMLContent } from '../components/Content';


export const ContactPageTemplate = ({ 
  title, 
  quote, 
  instructors, 
  helmet,
  content,
  contentComponent 
}) => {
  const AboutContent = contentComponent || Content

  return (
    <section className="page contact">
      <Breadcrumb path='/kontakt/' title='Kontakt'/>
      {helmet || ''}
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <div className="main-content">
              <div className="intro flex-center d-lg-none">
                <h1>{title}</h1>
              </div>

              <div className="about-us pt-20">
                <div className="p-label">
                  <h1>Om Kvarntakt20</h1>
                </div>
                <div className="content">
                  <div className="image-wrapper">
                    <img src={AboutImage} alt="hehe"/>
                  </div>
                  <div className="text-wrapper">
                    <h3>{quote ? quote : ''}</h3>
                    <AboutContent content={content}/>
                  </div>
                  
                  
                </div>
              </div>

              <div className="contact-info mt-15">
                <div className="p-label">
                  <h1>Frågor?</h1>
                </div>
                <div className="content">
                  <ContactInformation/>
                </div>
              </div>

              <div className="instructors mt-15">
                <div className="p-label">
                  <h1>Våra instruktörer</h1>
                </div>
                <div className="wrapper">
                {instructors.map((instructor, i) => {
                    return(
                      <div className="instructor" key={i}>
                        <div className="img-wrapper">
                          <img src={!!instructor.bild.childImageSharp ? instructor.bild.childImageSharp.fluid.src : ''} alt="bild"/>
                        </div>
                        
                        <div className="text">
                          <h2 className="title">{instructor.namn}</h2>
                          <p className="description">
                            {instructor.beskrivning} 
                          </p>
                          <br/>
                          <p className="courses"><span style={{'fontWeight': 'bold'}}>Kurser</span>: {instructor.kurser}</p>
                          <br/>
                          <p className="email">{instructor.mail}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="side-content">
                <Card className="side-card">
                  <CardHeader className="title" title="Betalning"/>
                  <CardContent style={{padding: 0}}>
                    <List>
                      <ListItem>
                        Betala enkelt med ett av följande sätt:
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <img src={SwishLogo} alt="Swish" style={{height: '50px', width: "auto"}}/>
                        </ListItemIcon>
                        <ListItemText primary='123 433 94 95'/>
                      </ListItem>
                       <ListItem>
                        <ListItemText primary='Bankgiro: 652-6560'/>
                      </ListItem>
                    </List>

                  </CardContent>
                </Card>
                <Card className="side-card">
                  <CardHeader className="title" title="Frågor?"/>
                  <CardContent style={{padding: 0}}>
                    <ContactInformation/>
                  </CardContent>
                </Card>

              <KeepUp title={'Följ oss'}/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired
}

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <ContactPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Kvarntakt20">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
              />
          </Helmet>
        }
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        quote={post.frontmatter.citat}
        instructors={post.frontmatter.instructors}
        contactInfo={post.frontmatter.contact_info}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  })
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        citat
        instructors{
          namn
          beskrivning
          kurser
          mail
          bild{
            childImageSharp {
              fluid(maxWidth: 200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          socials{
            fb
            twitter
            insta
          }
        }
        contact_info{
          telefon{
            kontakt_info{
              namn
              nummer
            }
            bild{
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          mail
          oppet_tider
        }
      }
    }
  }
`
